import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

export default function SuccessPage() {
  return (
    <>
      <Helmet>
        <title> Payment Success | Flastchat </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <CheckCircleIcon sx={{ fontSize: 48, color: "green", marginBottom: "16px" }} />
          <Typography variant="h3" paragraph>
            Payment Successful
          </Typography>

          <Typography sx={{ color: 'text.secondary', mt: 2, mb: 5 }}>
            Thank you for subscribing to Flastchat! Your payment was successfully processed. If you have any questions or need further assistance, please contact our support team.
          </Typography>

          <Button to="/app" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
