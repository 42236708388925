import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { supabase } from "./supabase";

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

const context = new AudioContext();

const availableVoices = {
    'English (United Kingdom)': [
      {
        name: 'Sonia',
        voiceURI: 'en-GB-SoniaNeural',
        default: false,
        lang: 'en-GB',
        localService: false
      },
      {
        name: 'Ryan',
        voiceURI: 'en-GB-RyanNeural',
        default: false,
        lang: 'en-GB',
        localService: false
      },
      {
        name: 'Libby',
        voiceURI: 'en-GB-LibbyNeural',
        default: false,
        lang: 'en-GB',
        localService: false
      },
      {
        name: 'Abbi',
        voiceURI: 'en-GB-AbbiNeural',
        default: false,
        lang: 'en-GB',
        localService: false
      },
    ],
    'English (United States)': [
      {
        name: 'Jenny',
        voiceURI: 'en-US-JennyNeural',
        default: false,
        lang: 'en-US',
        localService: false
      },
      {
        name: 'Guy',
        voiceURI: 'en-US-GuyNeural',
        default: false,
        lang: 'en-US',
        localService: false
      },
      {
        name: 'Aria',
        voiceURI: 'en-US-AriaNeural',
        default: false,
        lang: 'en-US',
        localService: false
      },
      {
        name: 'Davis',
        voiceURI: 'en-US-DavisNeural',
        default: false,
        lang: 'en-US',
        localService: false
      },
      {
        name: 'Amber',
        voiceURI: 'en-US-AmberNeural',
        default: false,
        lang: 'en-US',
        localService: false
      },
      {
        name: 'Ashley',
        voiceURI: 'en-US-AshleyNeural',
        default: false,
        lang: 'en-US',
        localService: false
      },
    ]
};

let speechTokenInfo = null;
let tokenFetchTime = null;

async function getSpeechToken() {
  const currentTime = new Date();
  if(speechTokenInfo && tokenFetchTime && Math.abs(currentTime - tokenFetchTime) <= 540000) {
    return speechTokenInfo;
  }

  const {data, error} = await supabase.functions.invoke('speech-token', { method: 'GET'});
  if(error) {
    throw error;
  }
  speechTokenInfo = data;
  tokenFetchTime = currentTime;
  return data;
}

async function getSpeechFromAzure(text, voiceSpeed = 1.0, voiceURI = "en-US-JennyNeural", setIsSpeaking = (isSpeaking, buffer, source) => {}) {
  const {token, region} = await getSpeechToken();

  // authorization for Speech service
  const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(token, region);
  speechConfig.speechSynthesisLanguage = "en-US";
  speechConfig.speechSynthesisVoiceName = voiceURI;

  // const player = new SpeechSDK.SpeakerAudioDestination();
  // player.onAudioEnd = () => setIsSpeaking(false);
  // setAudioPlayer(player);
  // const audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(player);

  const stream = SpeechSDK.AudioOutputStream.createPullStream();
  const audioConfig  = SpeechSDK.AudioConfig.fromStreamOutput(stream);

  // new Speech object
  let synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

  synthesizer.speakTextAsync(
    text,
    async (result) => {

      // Success function

      // display status
      if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {

        // load client-side audio control from Azure response
        // audioElement = document.getElementById("clientAudioAzure");
        try {
          const audioBuffer = await context.decodeAudioData(result.audioData);
          const source = context.createBufferSource();
          source.buffer = audioBuffer;
          source.onended = () => setIsSpeaking(false);
          source.playbackRate.value = voiceSpeed;
          const gainNode = context.createGain();
          gainNode.gain.value = isIOS() ? 2.5 : 1;
          source.connect(gainNode);
          gainNode.connect(context.destination);
          source.start(0);
          setIsSpeaking(true, audioBuffer, source);
        } catch (error) {
          console.error(error);
        }

      } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
        // display Error
        console.error(result.errorDetails);
      }

      // clean up
      synthesizer.close();
      synthesizer = undefined;
    },
    (err) => {

      // Error function
      console.error(err);

      // clean up
      synthesizer.close();
      synthesizer = undefined;
    });

}

function playSound(audioBuffer, voiceSpeed, setIsSpeaking = (isSpeaking, buffer, source) => {}) {
  try {
    const source = context.createBufferSource();
    source.buffer = audioBuffer;
    source.onended = () => setIsSpeaking(false);
    source.playbackRate.value = voiceSpeed;
    const gainNode = context.createGain();
    gainNode.gain.value = isIOS() ? 2.5 : 1;
    source.connect(gainNode);
    gainNode.connect(context.destination);
    source.start(0);
    setIsSpeaking(true, audioBuffer, source);
  } catch (error) {
    console.error(error);
  }
}

export { getSpeechToken, getSpeechFromAzure, availableVoices, playSound }