import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { isEmail, isStrongPassword } from 'validator';
// components
import Iconify from '../../../components/iconify';
import { useAuth } from "../../../contexts/auth";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const { user, logIn } = useAuth();
  const navigate = useNavigate();

  const [errorLogin, setErrorLogin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/app');
    }
  }, [navigate, user]);

  const handleLogin = async () => {

    if (!isEmail(email)) {
      setEmailError(true);
      return;
    }

    if (!password) {
      setPasswordError(true);
      return;
    }

    setLoading(true);
    try {
      const { error } = await logIn({ email, password });

      if (error) {
        console.log(error);
        setOpen(true);
        setErrorLogin(error?.message);
      } else {
        setErrorLogin('');
        navigate('/app');
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
      setErrorLogin('Sorry, something went wrong. Please try again');
    } finally {
      setLoading(false);
    }

  };

  const handleEmailChange = (event) => {
    if(emailError) {
      setEmailError(false);
    }
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    if(passwordError) {
      setPasswordError(false);
    }
    setPassword(event.target.value);
  };

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    setOpen(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          type="email"
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? 'Please enter a valid email address' : ''}
          required
          autoComplete="username"
          autoFocus
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" aria-label="Show password">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={handlePasswordChange}
          error={passwordError}
          helperText={
            passwordError ?
            'Please enter your password' : ''
          }
          required
          autoComplete="current-password"
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link to="/forgot-password" variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}  onClick={handleLogin}>
        Login
      </LoadingButton>

      <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleClose}>
        <Alert severity="error" elevation={6} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
          {errorLogin}
        </Alert>
      </Snackbar>
    </>
  );
}
