import PropTypes from 'prop-types';

// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up("sm")]: {
    width: 240,
  },
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter,
  // }),
  // '&.Mui-focused': {
  //   // width: 320,
  //   boxShadow: theme.customShadows.z8,
  // },
  // '& fieldset': {
  //   borderWidth: `1px !important`,
  //   borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  // },
}));

TopicsSearch.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function TopicsSearch({ filterName, onFilterName }) {
  return (
    <StyledSearch
      value={filterName}
      onChange={onFilterName}
      placeholder="Search topic..."
      startAdornment={
        <InputAdornment position="start">
          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
        </InputAdornment>
      }
    />
  )
}