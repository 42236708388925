import PropTypes from 'prop-types';
import { useState, useEffect } from "react";
// @mui
import { TextField, Autocomplete } from '@mui/material';

// ----------------------------------------------------------------------

TopicSelect.propTypes = {
  topic: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
};

export default function TopicSelect({ topic, options, onSelect }) {
  const [value, setValue] = useState(options.find(v => v.value === topic));

  useEffect(() => {
    setValue(options.find(v => v.value === topic));
  }, [options, topic]);

  const onChange = (event, topicObj) => {
    setValue(topicObj);
    onSelect(topicObj);
  }

  return (
    <Autocomplete
      disablePortal
      id="topic-select"
      size="small"
      options={options}
      // style={{minWidth: 220}}
      fullWidth
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label="Topic" />}
    />
  );
}
