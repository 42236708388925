import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Page404 from './pages/Page404';
import TopicsPage from './pages/TopicsPage';
import ChatPage from './pages/ChatPage';
import LandingPage from './pages/LandingPage';
import PricingPage from './pages/PricingPage';
import AccountPage from './pages/AccountPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SuccessPage from './pages/payment/SuccessPage';
import CancelledPage from './pages/payment/CancelledPage';
import SetupPage from './pages/SetupPage';
import SupportPage from './pages/SupportPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/app',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/app/topics" />, index: true },
        { path: 'setup', element: <SetupPage /> },
        { path: 'account', element: <AccountPage /> },
        { path: 'reset-password', element: <ResetPasswordPage />},
        { path: 'topics', element: <TopicsPage /> },
        { path: 'chat/:topic', element: <ChatPage /> },
      ],
    },
    {
      path: '/pricing',
      element: <DashboardLayout noRedirect/>,
      children: [
        { element: <PricingPage />, index: true },
      ],
    },
    {
      path: '/payment',
      element: <DashboardLayout noRedirect/>,
      children: [
        { path: 'success', element: <SuccessPage /> },
        { path: 'cancelled', element: <CancelledPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'signup',
      element: <SignupPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />
    },
    {
      path: 'reset-password',
      element: <ResetPasswordPage />
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <LandingPage />, index: true },
        { path: 'support', element: <SupportPage /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
