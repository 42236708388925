import { Helmet } from 'react-helmet-async';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { ChangePasswordForm } from '../sections/@dashboard/account';

import { useAuth } from "../contexts/auth"
import { supabase } from "../services/supabase"


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const mdUp = useResponsive('up', 'md');

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutID = setTimeout( () => navigate("/"), 5000);
    const { subscription } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log(event);
        if (event === "PASSWORD_RECOVERY") {
          clearTimeout(timeoutID);
        }
      }
    );
    return () => {
      clearTimeout(timeoutID);
      subscription?.unsubscribe();
    }
  }, [])

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/")
  //   }
  // }, [navigate, user]);

  return (
    <>
      <Helmet>
        <title> Reset Password | Flastchat </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        { user &&
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
              Reset Password
            </Typography>

            <ChangePasswordForm redirectToApp />
          </StyledContent>
        </Container>
        }
      </StyledRoot>
    </>
  );
}
