import {
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
// import { Button, Container, Grid, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import {
  Grid,
  Box,
  Typography,
  Button,
  Skeleton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { Link as RouterLink  } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';

import { supabase } from "../services/supabase";
import { useAuth } from "../contexts/auth";

export default function PricingPage () {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [plans, setPlans] = useState([]);
  const [hint, setHint] = useState('');
  const { user, isLoadingUser } = useAuth();

  useEffect(() => {
    supabase.functions.invoke('plans', { method: 'GET'})
      .then(({data, error}) => {
        if(error) {
          throw error;
        }
        setPlans(data.plans);
      })
      .catch((err) => {
        console.warn(err);
        // Connection refused
        const response = 'Failed to get the response, please refresh the page and try again.';
        setHint(response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const processSubscription = (planId) => async () => {
    try {
      setIsProcessing(true);
      const {data, error} = await supabase.functions.invoke('subscribe', {
        body: {
          priceId: planId,
        }
      });
      if(error) {
        throw error;
      }
      console.log(data);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
      await stripe.redirectToCheckout({ sessionId: data.id });
    } catch(err) {
      console.warn(err);
      // Connection refused
      const response = 'Failed to get the response, please refresh the page and try again.';
      setHint(response);
    } finally {
      setIsProcessing(false);
    }
  };

  const loadPortal = async () => {
    try {
      setIsProcessing(true);
      const {data, error} = await supabase.functions.invoke('stripe-portal', { method: 'GET'});
      if(error) {
        throw error;
      }
      window.location.href = data.url;
    } catch(err) {
      console.warn(err);
      const response = 'Failed to get the response, please refresh the page and try again.';
      setHint(response);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    setHint('');
  };

  const showSubscribeButton = !!user && !user.is_subscribed;
  const showCreateAccountButton = !user;
  const showManageSubscriptionButton = !!user && user.is_subscribed;

  return (
    <>
      <Helmet>
        <title> Pricing | Flastchat </title>
      </Helmet>

      <Container maxWidth="lg">
        <Box
          sx={{
            py: { xs: 3, md: 5 },
            textAlign: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Pricing Plans
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Choose a plan that works for you.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} sx={{ display: plans.length <= 0 ? 'block' : 'flex', flexGrow: 1 }}>
          { plans.length <= 0 ?
            <Skeleton variant="rounded" height={500} />
            :
            <Paper elevation={8} sx={{ p: {xs: 4, sm: 2, md: 4}, borderRadius: 5, display: 'flex', flexDirection: 'column', flexGrow: 1, border: !isLoadingUser && showSubscribeButton ? 'solid #92b0df' : '' }} >
              <Typography variant="h5" gutterBottom>
                Free
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                $0 <span style={{fontSize: '0.5em'}}>{plans[0].currency.toUpperCase()}</span> / {plans[0].interval}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
              <Typography component="ul" sx={{ pl: 0, mb: 3 }}>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="3 predefined topics" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="30 monthly practice sessions" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Feedback and suggestions" />
                </ListItem>
              </Typography>
              </Box>
              {!isLoadingUser && showCreateAccountButton && (
                <Button variant="outlined" fullWidth sx={{
                  borderRadius: 35 }}
                  to="/signup"
                  component={RouterLink}
                >
                  Get started
                </Button>
              )}
            </Paper>}
          </Grid>
          <Grid item xs={12} sm={4} sx={{ display: plans.length <= 0 ? 'block' : 'flex', flexGrow: 1 }}>
          { plans.length <= 0 ?
            <Skeleton variant="rounded" height={500} />
            :
            <Paper elevation={8} sx={{ p: {xs: 4, sm: 2, md: 4}, borderRadius: 5, display: 'flex', flexDirection: 'column', flexGrow: 1, border: !isLoadingUser && showManageSubscriptionButton ? 'solid #92b0df' : ''  }}>
              <Typography variant="h5" gutterBottom>
                {plans[0].name}
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                ${plans[0].price / 100} <span style={{fontSize: '0.5em'}}>{plans[0].currency.toUpperCase()}</span> / {plans[0].interval}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
              <Typography component="ul" sx={{ pl: 0, mb: 3 }}>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="26 predefined topics" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="200 monthly practice sessions" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Feedback and suggestions" />
                </ListItem>
              </Typography>
              </Box>
              {!isLoadingUser &&
                <>
                  {showSubscribeButton && (
                    <LoadingButton variant="outlined" fullWidth sx={{
                      borderRadius: 35 }}
                      onClick={processSubscription(plans[0].id)}
                      loading={isProcessing}
                    >
                      Subscribe
                    </LoadingButton>
                  )}
                  {showCreateAccountButton && (
                    <Button variant="outlined" fullWidth sx={{
                      borderRadius: 35 }}
                      to="/signup"
                      component={RouterLink}
                    >
                      Get started
                    </Button>
                  )}
                  {showManageSubscriptionButton && (
                    <LoadingButton variant="outlined" fullWidth sx={{
                      borderRadius: 35 }}
                      onClick={loadPortal}
                      loading={isProcessing}
                    >
                      Manage subscription
                    </LoadingButton>
                  )}
                </>
              }
            </Paper>}
          </Grid>
          <Grid item xs={12} sm={4}>
          { plans.length <= 0 ?
            <Skeleton variant="rounded" height={500} />
            :
            <Paper elevation={8} sx={{ p: {xs: 4, sm: 2, md: 4}, borderRadius: 5 }}>
              <Typography variant="h5" gutterBottom>
                {plans[1].name}
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                ${plans[1].price / 100} <span style={{fontSize: '0.5em'}}>{plans[1].currency.toUpperCase()}</span> / {plans[1].interval}
              </Typography>
              <Typography component="ul" sx={{ pl: 0, mb: 3 }}>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="26 predefined topics" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="300 monthly practice sessions" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Feedback and suggestions" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="AI-driven practice" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="User-defined topics" />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ color: 'success.main' }}>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Flashcards" />
                </ListItem>
              </Typography>
              <Button variant="contained" fullWidth disabled sx={{
                borderRadius: 35}}>
                Coming Soon
              </Button>
            </Paper>}
          </Grid>
        </Grid>

        <Snackbar open={!!hint} autoHideDuration={10000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleClose}>
          <Alert severity={'error'} elevation={6} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
            {hint}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};
