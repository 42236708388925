import { createContext, useState, useEffect, useContext, useRef } from "react";

import * as Storage from '../utils/storage';

const savedData = Storage.load();

const AppSettingsContext = createContext()

export function useAppSettings() {
  return useContext(AppSettingsContext);
}

export function AppSettingsProvider({ children }) {
  const defaultSettingsRef = useRef({
    host: process.env.REACT_APP_URL_SUPABASE,
    voiceURI: 'en-US-JennyNeural',
    voiceSpeed: 1,
  });

  const [appSettings, setAppSettings] = useState({
    host: (savedData?.host) ?? defaultSettingsRef.current.host,
    voiceURI:
      (savedData?.voiceURI?.startsWith('en-') ? savedData?.voiceURI : defaultSettingsRef.current.voiceURI),
    voiceSpeed:
      (savedData?.voiceSpeed) ??
      defaultSettingsRef.current.voiceSpeed,
  });

  // Update app settings in local storage when they change
  // useEffect(() => {
  //   localStorage.setItem("appSettings", JSON.stringify(appSettings));
  // }, [appSettings]);

  const updateAppSettings = (newSettings) => {
    setAppSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
  };

  const saveAppSettings = (settings) => {
    Storage.save(settings);
  };

  return (
    <AppSettingsContext.Provider value={{ appSettings, defaultSettingsRef, setAppSettings, saveAppSettings }}>
      {children}
    </AppSettingsContext.Provider>
  )
}