import { useState, useEffect, useRef, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Button, Paper, Avatar, Skeleton, IconButton, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { green } from '@mui/material/colors';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AssistantIcon from '@mui/icons-material/Assistant';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import UndoIcon from '@mui/icons-material/Undo';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { keyframes } from '@mui/system';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import useMediaQuery from '@mui/material/useMediaQuery';


import { supabase } from "../../../services/supabase";
import { getSpeechFromAzure, playSound } from '../../../services/azure'
import { useAuth } from "../../../contexts/auth";

import SoundAnimation from './SoundAnimation';

const TutorAvatarUrl = `/assets/images/avatars/tutor.png`

const blink = keyframes`
  from { opacity: 0.2; }
  to { opacity: 1; }
`;

const BlinkedBox = styled('div')({
  // backgroundColor: 'red',
  // width: 30,
  // height: 30,
  display: 'flex',
  alignItems: 'center',
  animation: `${blink} 1.3s linear infinite`,
});

const MessageList = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  // overflowY: "scroll",
});

const MessageContainer = styled("div", {
  shouldForwardProp: (propName) => propName !== 'isMine',
})(({ theme, isMine }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    // justifyContent: isMine ? "flex-end" : "flex-start",
    flexDirection: isMine ? "row-reverse" : "row",
  },
  marginBottom: "8px",
}));

const MessageStyledRoot = styled(Paper, {
  shouldForwardProp: (propName) => propName !== 'isMine' && propName !== 'isActive',
})(({ theme, isMine, isActive }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "70%",
  },
  padding: "8px 16px",
  borderRadius: "16px",
  background: isMine ? "#f5f5f5" : "#0099ff",
  color: isMine ? "#333" : "#fff",
  transitionProperty: "opacity",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms",
  opacity: isActive ? "100%" : "90%",
}));

const Message = ({ isMine, isActive, message, question, settings, currentMessageId, undoLastVoiceInput, isListening, targetScore, partIndex, index, activeIndex, setActiveIndex, isStarting, setIsStarting }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [processResults, setProcessResults] = useState([]);
  const [processType, setProcessType] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [audioBufferSource, setAudioBufferSource] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const open = Boolean(anchorEl);

  const audioRef = useRef(null);

  const stopSpeaking = useCallback(() => {
    // window.speechSynthesis.cancel();
    if(audioBufferSource != null) {
      audioBufferSource.stop();
    } else {
      // console.log('stopSpeaking, no component!')
    }
  }, [audioBufferSource]);

  useEffect(() => {
    if(!isMine && index >= 0) {
      setActiveIndex(index);
      getAudio(message);
    }
  }, []);

  useEffect(() => () => {
    // console.log(`exit! isSpeaking: ${  isSpeaking}`);
    stopSpeaking();
  }, [stopSpeaking]);

  useEffect(() => {
    if ((activeIndex !== index) || isListening){
      stopSpeaking();
      setIsSpeaking(false);
    }

  }, [activeIndex, index, isActive, isListening, stopSpeaking]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSpeak = (text) => {
    if(isSpeaking) {
      stopSpeaking();
      setIsSpeaking(false);
    } else {
      // eslint-disable-next-line no-lonely-if
      setActiveIndex(index);
      if(audioBuffer != null) {
        playSound(audioBuffer, settings.voiceSpeed, (isSpeaking, buffer, source) => {
          setIsSpeaking(isSpeaking);
          if(source) {
            setAudioBufferSource(source);
          }
        });
      } else {
        getAudio(text);
      }
    }
  };

  async function getAudio(text) {
    return getSpeechFromAzure(text, settings.voiceSpeed, settings.voiceURI, (isSpeaking, buffer, source) => {
      setIsSpeaking(isSpeaking);
      if(buffer) {
        setAudioBuffer(buffer);
        setAudioBufferSource(source);
      }
    });
  }

  const handleCheck = () => {
    checkAnswer(`Question: ${question}\nMy answer: ${message}\n`);
    handleClose();
  };

  const handleSuggestion = () => {
    suggestWords(`Question: ${question}\nMy answer: ${message}\n`);
    handleClose();
  };

  const handleExample = () => {
    answerQuestion(`Question: ${question}\n`);
    handleClose();
  };

  const getResponseFromGPT = (requestText, requestType, path) => {
    setProcessType(requestType);
    if(processResults.some(result => result.type === requestType && !result.failed)) {
      return;
    }

    setIsProcessing(true);

    // abortRef.current = new AbortController();

    supabase.functions.invoke(`instructor/${requestType}`, {
      body: {
        text: requestText,
        targetScore,
        parentMessageId: currentMessageId || undefined,
      }
    })
      .then(({data, error}) => {
        if(error) {
          throw error;
        }
        setProcessResults((oldResults) => {
          const index = oldResults.findIndex((result) => result.type === requestType);
          if(index >= 0) {
            oldResults[index] =  ({ type: requestType, text: data.answer });
            return oldResults;
          }
          return [
            ...oldResults,
            { type: requestType, text: data.answer },
          ];
        });
      })
      .catch((err) => {
        console.warn(err);
        // Connection refused
        const response = 'Failed to get the response, please try again.';

        setProcessResults((oldResults) => [
          ...oldResults,
          { type: requestType, text: response, failed: true },
        ]);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const checkAnswer = (text) => {
    getResponseFromGPT(text, 'check', 'chatgpt/check');
  };

  const suggestWords = (text) => {
    getResponseFromGPT(text, 'suggest', 'chatgpt/suggest');
  };

  const answerQuestion = (text) => {
    getResponseFromGPT(text, 'answer', 'chatgpt/answer');
  };

  const displayError = (error) => {
    window.alert(JSON.stringify(error));
  }

  return (<MessageStyledRoot isMine={isMine} isActive={isActive}>
    <p style={{whiteSpace: 'pre-line', marginBlockStart: 0, marginBlockEnd: 0}}>
      {message}
      { (!isListening || !isMine || !isActive) &&
        <>
          {(index >= 0) &&
          <IconButton aria-label="speak" sx={{padding: 1, ...(!isMine && {color:'white'})}} disabled={isListening} onClick={() => handleSpeak(message)}>
            {isSpeaking? (
              <BlinkedBox>
               <VolumeUpIcon />
              </BlinkedBox>
              ) : (
              <VolumeUpIcon />
            )}

          </IconButton>
          }

          {(index < 0) &&
          <IconButton aria-label="start" sx={{padding: 1, ...(!isMine && {color:'white'})}} disabled={isStarting} onClick={() => setIsStarting(true)}>
            <PlayCircleOutlineIcon />
          </IconButton>
          }

          {/* <audio id="serverAudioStream" controls preload="none" onError={displayError}></audio> */}
          {/* eslint-disable jsx-a11y/media-has-caption */
          // <audio ref={audioRef} type="audio/mpeg" />
          }
          {isMine && partIndex <= 3 &&
            <>
              { isActive &&
                <IconButton
                  aria-label="redo"
                  // sx={{padding: 1}}
                  disabled={isListening}
                  onClick={undoLastVoiceInput}
                >
                  <UndoIcon/>
                </IconButton>
              }

              <br/>
              <Button variant="outlined" sx={{ mr: 1, mb: 1, borderRadius: 35}} size="small" startIcon={<SpellcheckIcon fontSize="small" />} onClick={handleCheck}>
                Check
              </Button>
              <Button variant="outlined" sx={{ mr: 1, mb: 1, borderRadius: 35}} size="small" startIcon={<AssistantIcon fontSize="small" />} onClick={handleSuggestion}>
                Tips
              </Button>
              <Button variant="outlined" size="small" sx={{ mb: 1, borderRadius: 35}} startIcon={<QuestionAnswerIcon fontSize="small" />} onClick={handleExample}>
                Example
              </Button>
            </>
          }
        </>
      }
    </p>

    {isProcessing ?
      <Skeleton width={200} height={30}/>
      :
      processType && <p style={{whiteSpace: 'pre-line', marginBlockStart: 0, marginBlockEnd: 0}}>
        <br />
        <span style={{paddingRight: '8px'}}>
          {processType === 'check' && <SpellcheckIcon />}
          {processType === 'suggest' && <AssistantIcon />}
          {processType === 'answer' && <QuestionAnswerIcon />}
        </span>
        {processResults.find(result => result.type === processType)?.text}
        {/* <IconButton aria-label="speak" sx={{padding: 1}} onClick={() => handleGPTSpeak(processResults.find(result => result.type === processType)?.text)}>
          {isGPTSpeaking? (
            <BlinkedBox>
              <VolumeUpIcon />
            </BlinkedBox>
            ) : (
            <VolumeUpIcon />
          )}
        </IconButton> */}
      </p>
    }
  </MessageStyledRoot>);
}

const ChatMessage = ({topic, messages, transcript, isListening, isInitializing, isProcessing, settings, currentMessageId, undoLastVoiceInput, isStarting, setIsStarting}) => {
  const [message, setMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  // const [messages, setMessages] = useState([{ text: "Hi Jenny, How r u today? Did you train yesterday", isMine: false }]);
  const { user } = useAuth();

  const bottomDivRef = useRef(null);

  // Scroll to bottom when user is speaking a prompt
  useEffect(() => {
    if (isListening) {
      bottomDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [bottomDivRef, isListening]);

  useEffect(() => {
    setIsStarting(false);
  }, [topic]);

  // Scroll to bottom when there is a new response
  useEffect(() => {
    bottomDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [bottomDivRef, messages.length]);

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const sendMessage = () => {
    // setMessages([...messages, { text: message, isMine: true }]);
    setMessage("");
  };

  const startHint = `Hello, please click on the Start button to begin our practice session with the topic of ${topic}.`;
  const hint = `Here's your first question for part one:\n`;

  return (
      <MessageList>
        <MessageContainer>
          <Avatar style={{ marginRight: "8px" }} src={TutorAvatarUrl} alt="AI">AI</Avatar>
          <Message
              role="button"
              isActive={ messages.length === 1 }
              message={startHint}
              isListening={isListening}
              settings={settings}
              index={-1}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              isStarting={isStarting}
              setIsStarting={setIsStarting}
            />
        </MessageContainer>
        { isStarting &&
          <MessageContainer>
            <Avatar style={{ marginRight: "8px" }} src={TutorAvatarUrl} alt="AI">AI</Avatar>
            {isInitializing ?
              <Skeleton width={200} height={40}/>
              :
              <Message
                role="button"
                isActive={ messages.length === 1 }
                message={(messages[0]?.partIndex === 1 ? hint  : '') + messages[0]?.text}
                isListening={isListening}
                settings={settings}
                index={0}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            }
          </MessageContainer>
        }

        {messages.slice(1).map(({ type, text }, index) => {
          index += 1;
          const getIsActive = () => {
            if (isListening) {
              return false;
            }
            if (type === 'prompt') {
              return (
                (index === messages.length - 1 || index === messages.length - 2)
              );
            }
            if (type === 'response') {
              return index === messages.length - 1;
            }
            return false;
          };
          const isMine = type === 'prompt';
          return (
            <MessageContainer key={index} isMine={isMine}>
              {isMine ? (
                <>
                  <Avatar style={ smUp ? ({ marginLeft: "8px" }) : ({ marginRight: "8px" })} alt="Me" sx={{ bgcolor: green[500] }}>{user.email[0]}</Avatar>
                  <Message
                    isMine={isMine}
                    role="button"
                    isActive={getIsActive()}
                    message={text}
                    question={messages[index-1]?.text}
                    settings={settings}
                    currentMessageId={currentMessageId}
                    undoLastVoiceInput={undoLastVoiceInput}
                    isListening={isListening}
                    targetScore={user.user_metadata.target_ielts_score}
                    partIndex={messages[index-1]?.partIndex}
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                  />
                </>
              ) : (
                <>
                  <Avatar style={{ marginRight: "8px" }} src={TutorAvatarUrl} alt="AI">AI</Avatar>
                  <Message
                    isMine={isMine}
                    role="button"
                    isActive={getIsActive()}
                    message={text}
                    isListening={isListening}
                    settings={settings}
                    index={index}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                  />
                </>
              )}
            </MessageContainer>
          );
        })}
        {isListening &&
          <MessageContainer isMine>
            <Avatar style={smUp ? ({ marginLeft: "8px" }) : ({ marginRight: "8px" })} alt="Me" sx={{ bgcolor: green[500] }}>{user.email[0]}</Avatar>
            {transcript ?
              <Message
                isMine
                isActive
                message={transcript}
                question={messages[messages.length-1]?.text}
                settings={settings}
                currentMessageId={currentMessageId}
                undoLastVoiceInput={undoLastVoiceInput}
                isListening={isListening}
                targetScore={user.user_metadata.target_ielts_score}
                partIndex={messages[messages.length-1]?.partIndex}
                index={messages.length}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
              :
              <Skeleton width={200} height={40}/>
            }
          </MessageContainer>
        }
        {isProcessing &&
          <MessageContainer>
            <Avatar style={{ marginRight: "8px" }} src={TutorAvatarUrl} alt="AI">AI</Avatar>
            <Skeleton width={200} height={40}/>
          </MessageContainer>
        }
        <div ref={bottomDivRef} />
      </MessageList>

  );
};

export default ChatMessage;
