import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Select, Snackbar, Alert, MenuItem, FormControl, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { isEmail, isStrongPassword } from 'validator';
// components
import Iconify from '../../../components/iconify';
import { useAuth } from "../../../contexts/auth";

// ----------------------------------------------------------------------

export default function SignupForm() {
  const [showPassword, setShowPassword] = useState(false);

  const { user, signUp } = useAuth();
  const navigate = useNavigate();

  const [errorLogin, setErrorLogin] = useState('');
  const signupHint = 'Thank you! We will send you a confirmation email shortly with an activation link to get you started with Flastchat.';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [score, setScore] = useState(6);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHint, setPasswordHint] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/app');
    }
  }, [navigate, user]);

  const handleSignUp = async () => {
    // navigate('/dashboard', { replace: true });

    if (!isEmail(email)) {
      setEmailError(true);
      return;
    }

    if (!isStrongPassword(password, { minLength: 8, minSymbols: 0, minNumbers: 1 })) {
      setPasswordError(true);
      return;
    }

    setLoading(true);
    try {
      const { error } = await signUp({
        email,
        password
        // options: {
        //   data: {
        //     target_ielts_score: score,
        //   }
        // }
      });

      if (error) {
        console.log(error);
        setErrorLogin(error?.message);
      } else {
        setErrorLogin('');
        setEmail('');
        setPassword('');
        setScore(6);
        // navigate('/');
      }
    } catch (error) {
      console.log(error);
      setErrorLogin('Sorry, there is an error. Please try again');
    } finally {
      setLoading(false);
      setOpen(true);
    }

  };

  const handleEmailChange = (event) => {
    if(emailError) {
      setEmailError(false);
    }
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    if(passwordError) {
      setPasswordError(false);
    }
    setPassword(event.target.value);
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          type="email"
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? 'Please enter a valid email address' : ''}
          required
          autoComplete="username"
          autoFocus
        />
        {/* <FormControl fullWidth>
          <InputLabel id="score-select-label">Target IELTS Score</InputLabel>
          <Select
            labelId="score-select-label"
            id="score-select"
            value={score}
            label="Target IELTS Score"
            onChange={handleScoreChange}
            required
          >
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={6.5}>6.5</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={7.5}>7.5</MenuItem>
            <MenuItem value={8}>8</MenuItem>
          </Select>
        </FormControl> */}

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" aria-label="Show password">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={handlePasswordChange}
          onFocus={() => setPasswordHint(true)}
          // onBlur={() => setPasswordHint(false)}
          error={passwordError}
          helperText={
            (passwordError || passwordHint) ?
            'Password must be at least 8 characters long and contain at least a lower-case letter, an upper-case letter, and a number' : ''
          }
          required
          autoComplete="new-password"
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 5 }} loading={loading}  onClick={handleSignUp}>
        Create Account
      </LoadingButton>

      <Snackbar open={open} autoHideDuration={errorLogin ? 10000 : 30000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleClose}>
        <Alert severity={errorLogin ? 'error' : 'info'} elevation={6} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
          {errorLogin || signupHint}
        </Alert>
      </Snackbar>
    </>
  );
}
