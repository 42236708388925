import { useState } from 'react';
import { useNavigate } from "react-router-dom"
// @mui
import { Container, Stack, TextField, IconButton, InputAdornment, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { isStrongPassword } from 'validator';
// components
import Iconify from '../../../components/iconify';
import { useAuth } from "../../../contexts/auth";

// ----------------------------------------------------------------------

export default function ChangePasswordForm({redirectToApp}) {
  const { user, updatePassword } = useAuth();

  const [errorLogin, setErrorLogin] = useState('');
  const [updateHint, setUpdateHint] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHint, setPasswordHint] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const navigate = useNavigate();

  const handleUpdate = async () => {
    // navigate('/dashboard', { replace: true });

    if (!isStrongPassword(password, { minLength: 8, minSymbols: 0, minNumbers: 1 })) {
      setPasswordError(true);
      return;
    }

    if (confirmPassword !== password) {
      setConfirmPasswordError(true);
      return;
    }

    setLoading(true);
    try {
      const { error } = await updatePassword(password);

      if (error) {
        console.log(error);
        setErrorLogin(error?.message);
      } else {
        setErrorLogin('');
        setUpdateHint( `Password changed! You can use new password to log into Flastchat.`);
      }
    } catch (error) {
      console.log(error);
      setErrorLogin('Sorry, there is an error. Please try again');
    } finally {
      setLoading(false);
      setOpen(true);
      setPassword('');
      setConfirmPassword('')
    }

  };

  const handlePasswordChange = (event) => {
    if(passwordError) {
      setPasswordError(false);
    }
    setPassword(event.target.value);
  };

  const handleConfrimPasswordChange = (event) => {
    if(confirmPasswordError) {
      setConfirmPasswordError(false);
    }
    setConfirmPassword(event.target.value);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
    if (redirectToApp) {
      navigate("/app");
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="password"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" aria-label="Show password">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={handlePasswordChange}
          onFocus={() => setPasswordHint(true)}
          // onBlur={() => setPasswordHint(false)}
          error={passwordError}
          helperText={
            // (passwordError || passwordHint) &&
            'Password must be at least 8 characters long and contain at least a lower-case letter, an upper-case letter, and a number'
          }
          required
          autoComplete="new-password"
        />
        <TextField
          name="confirmPassword"
          label="Confirm New Password"
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end" aria-label="Show confirm password">
                  <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={confirmPassword}
          onChange={handleConfrimPasswordChange}
          // onBlur={() => setPasswordHint(false)}
          error={confirmPasswordError}
          helperText={
            (confirmPasswordError) ?
            'Confirm New Password and New Passowrd do not match' : ''
          }
          required
          autoComplete="new-password"
          pb={5}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 5 }} loading={loading}  onClick={handleUpdate}>
        Change Password
      </LoadingButton>

      <Snackbar open={open} autoHideDuration={errorLogin ? 10000 : 10000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleClose}>
        <Alert severity={errorLogin ? 'error' : 'info'} elevation={6} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
          {errorLogin || updateHint}
        </Alert>
      </Snackbar>
    </>
  );
}
