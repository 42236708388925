import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
// @mui
import { Container, Stack, Typography } from '@mui/material';
//
import { filter } from 'lodash';
// components
import { TopicList, TopicsSearch } from '../sections/@dashboard/topics';
import { useAuth } from "../contexts/auth";
// mock
import TOPICS from '../_data/topics';

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_topic) => _topic.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TopicsPage() {
  const { user } = useAuth();
  const [filterName, setFilterName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.user_metadata?.target_ielts_score) {
      navigate('/app/setup');
    }
  }, [navigate, user]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredTopics = applySortFilter(TOPICS, getComparator('asc', 'name'), filterName);

  const isNotFound = !filteredTopics.length && !!filterName;

  return (
    user?.user_metadata?.target_ielts_score &&
    <>
      <Helmet>
        <title> Topics | Flastchat </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Topics
        </Typography>

        <Stack mb={3} direction="row" alignItems="center" justifyContent="flex-start">
          <TopicsSearch filterName={filterName} onFilterName={handleFilterByName} />
        </Stack>

        <TopicList topics={filteredTopics} />
        {/* <ProductCartWidget /> */}
      </Container>
    </>
  );
}
