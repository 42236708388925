import { Box, Typography, Link } from '@mui/material';


function Copyright() {
  const currentYear = new Date().getFullYear();
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {`© 2023${currentYear === 2023 ? '' : (` - ${currentYear}`)} Flastchat. All Rights Reserved.`} <br/>
      <Link color="inherit" underline='none' href="/support" sx={{ mr: 3}}>
        Support
      </Link>{' '}
      <Link color="inherit" underline='none' href="/terms.html" sx={{ mr: 3}}>
        Terms of Use
      </Link>{' '}
      <Link color="inherit" underline='none' href="/privacy.html">
        Privacy
      </Link>{' '}
      {/* {new Date().getFullYear()}
      {'.'} */}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box sx={{ p: 3, height: { xs: '80px', lg: '80px', xl: '110px' }, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }} component="footer">
      {/* <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        Something here to give the footer a purpose!
      </Typography> */}
      <Copyright />
    </Box>
  );
}