import { createContext, useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { supabase } from "../services/supabase"

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

function isTokenExpired(session) {
  if (!session?.expires_at) {
    console.log('expires_at', session?.expires_in);
    return true;
  }

  const expiryDate = new Date(session.expires_at * 1000);
  const currentDate = new Date();

  // console.log(expiryDate);
  return expiryDate <= currentDate;
}

export function AuthProvider({ children }) {
  const [session, setSession] = useState()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    const getUserProfile = async (session) => {
      if (session) {
        const { data: profile } = await supabase
          .from("profile")
          .select("*")
          .eq("id", session.user.id)
          .single();

        setUser({
          ...session.user,
          ...profile,
        });
        // setSession(session);
        setLoading(false);
      }
    };

    const gotSession = localStorage.getItem("authSession")
    if (gotSession) {
      // console.log("Retrieved: ", gotSession)
      const sessionObj = JSON.parse(gotSession);
      if(!isTokenExpired(sessionObj)) {
        setSession(sessionObj)
        setUser(sessionObj.user)
        getUserProfile(sessionObj)
      }
    }
    function getSession() {
      // setLoading(false)
      const { subscription } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          if (session) {
            // console.log("New session: ", session)
            setUser(session.user)
            localStorage.setItem("authSession", JSON.stringify(session))
            setSession(session)
            getUserProfile(session)
          } else {
            localStorage.removeItem("authSession")
            setSession(null)
            setUser(null)
            setLoading(false)
          }

          // setLoading(false)

          // if (event === "PASSWORD_RECOVERY") {
          //   navigate("/app/reset-password");
          // }
        }
      )
      return () => {
        subscription?.unsubscribe()
      }
    }
    return getSession();
  }, []);

  useEffect(() => {
    if (user) {
      const subscription = supabase
        .channel('any')
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'profile', filter: `id=eq.${user.id}` }, payload => {
          setUser({ ...user, ...payload.new });
        })
        .subscribe();

      return () => {
        supabase.removeChannel(subscription);
      };
    }

    return () => {}
  }, [user]);

  // const logIn = async (data) => {
  //   const result = await supabase.auth.signInWithPassword(data);
  //   if(!result.error) {
  //     setLoading(true);
  //   }
  //   return result;
  // }

  const value = {
    signUp: (data) => supabase.auth.signUp(data),
    logIn: (data) => supabase.auth.signInWithPassword(data),
    signOut: () => supabase.auth.signOut(),
    updateUser: (email, score) => supabase.auth.updateUser({ ... email && { email }, ... score && { data: { target_ielts_score : score }}}),
    updatePassword: (password) => supabase.auth.updateUser({ password }),
    resetPasswordForEmail: (email) => supabase.auth.resetPasswordForEmail(email, {redirectTo: `${process.env.REACT_APP_URL_FRONTEND}/reset-password`}),
    user,
    isLoadingUser: loading
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}