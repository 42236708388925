import ReactDOM from 'react-dom/client';
import { PostHogProvider} from 'posthog-js/react';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------
const options = {
  api_host: process.env.REACT_APP_POSTHOG_HOST,
  disable_session_recording: true,
};
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_POSTHOG_KEY}
    options={options}
  >
    <App />
  </PostHogProvider>);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
