// @mui
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// ----------------------------------------------------------------------

export default function ScoreSelect({score, setScore}) {
  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="score-select-label">Target IELTS Score</InputLabel>
      <Select
        labelId="score-select-label"
        id="score-select"
        value={score}
        label="Target IELTS Score"
        onChange={handleScoreChange}
        required
      >
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={6.5}>6.5</MenuItem>
        <MenuItem value={7}>7</MenuItem>
        <MenuItem value={7.5}>7.5</MenuItem>
        <MenuItem value={8}>8</MenuItem>
      </Select>
    </FormControl>
  );
}
