import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../../components/logo';
import Header from '../dashboard/header';
import Footer from '../../components/footer/Footer'

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  // overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Main = styled('div')({
  flexGrow: 1,
  // overflow: 'auto',
  minHeight: '100%',
});


const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  return (
    <>
      {/* <StyledHeader>
        <Logo />
      </StyledHeader> */}
      {/* <Header />


      <Outlet />
      <Footer /> */}
      <StyledRoot>
        <Header />

        <MainRoot>
          <Main>
            <Outlet />
          </Main>
        </MainRoot>
        <Footer />
      </StyledRoot>
    </>
  );
}
