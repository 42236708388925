import { faker } from '@faker-js/faker';
import { sample, sortBy } from 'lodash';

// ----------------------------------------------------------------------

const TOPIC = sortBy([
  { key: 'shopping', name: 'Shopping', emoji: '🛍️', status: 'disabled' },
  { key: 'clothing', name: 'Clothing', emoji: '👚', status: 'disabled' },
  { key: 'buildings', name: 'Buildings', emoji: '🏬' },
  { key: 'sports', name: 'Sports', emoji: '⛹️‍♀️', status: 'disabled' },
  { key: 'outdoor-activities', name: 'Outdoor Activities', emoji: '🏄🏻‍♀️', status: 'disabled' },
  { key: 'games', name: 'Games', emoji: '🧩', status: 'disabled' },
  { key: 'food', name: 'Food', emoji: '🍔', status: 'disabled' },
  { key: 'travel', name: 'Travel', emoji: '🚙', status: 'disabled' },
  { key: 'pets', name: 'Pets', emoji: '🐶', status: 'disabled' },
  { key: 'movies-tv', name: 'Movies & TV', emoji: '🎦', status: 'disabled' },
  { key: 'music', name: 'Music', emoji: '🎼', status: 'disabled' },
  { key: 'weather-season', name: 'Weather & Seasons', emoji: '☃️', status: 'disabled' },
  { key: 'work', name: 'Work', emoji: '💼', status: 'disabled' },
  { key: 'family', name: 'Family', emoji: '👨‍👩‍👧‍👦', status: 'disabled' },
  { key: 'friends', name: 'Friends', emoji: '👭', status: 'disabled' },
  { key: 'study', name: 'Study', emoji: '🎓', status: 'disabled' },
  { key: 'personality', name: 'Personality', emoji: '🤓', status: 'disabled' },
  { key: 'books', name: 'Books', emoji: '📚' },
  { key: 'hometown', name: 'Hometown', emoji: '🏘️', status: 'disabled' },
  { key: 'parties', name: 'Parties', emoji: '🎉', status: 'disabled' },
  { key: 'festival', name: 'Festival', emoji: '🎄', status: 'disabled' },
  { key: 'childhood', name: 'Childhood', emoji: '🧒🏻' },
  { key: 'technology', name: 'Technology', emoji: '🖥', status: 'disabled' },
  { key: 'places', name: 'Places', emoji: '📌', status: 'disabled' },
  { key: 'gifts', name: 'Gifts', emoji: '🎁', status: 'disabled' },
  { key: 'life-experience', name: 'Life Experience', emoji: '🏆', status: 'disabled' },
  { key: 'letters', name: 'Letters', emoji: '📝', status: 'disabled' },
], ['key']);

// ----------------------------------------------------------------------

const topics = [...Array(27)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    emoji: TOPIC[index].emoji,
    name: TOPIC[index].name,
    key: TOPIC[index].key,
    status: TOPIC[index].status  // sample(['new', '', '']),
  };
});

export default topics;
