import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  Fab,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Select,
  Tooltip,
  ListSubheader,
  Slider,
  FormControl
} from '@mui/material';

import {
  Headphones
} from 'react-feather';

import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import { isDesktop, isMobile, isSafari } from 'react-device-detect';

//
import { useAppSettings } from '../../../contexts/appSettings';
import { getSpeechFromAzure, availableVoices } from '../../../services/azure';

// ----------------------------------------------------------------------

interface VoiceMappings {
  [group: string]: SpeechSynthesisVoice[];
}

export default function VoiceForm({settings, setSettings}) {
  const {
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    transcript,
    listening: isListening,
    // finalTranscript,
    resetTranscript,
  } = useSpeechRecognition();

  const { defaultSettingsRef } = useAppSettings();

  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);

  const selectedVoice = useMemo(() => {
    return availableVoices.values?.find((voice) => voice.voiceURI === settings.voiceURI);
  }, [settings.voiceURI]);

  const speak = useCallback(
    (text: string, setSpeaking: (boolean) => void = () => {}) => {
      window.speechSynthesis.cancel();
      const utterance = new SpeechSynthesisUtterance(text);
      if (selectedVoice) {
        utterance.voice = selectedVoice;
      }
      utterance.rate = settings.voiceSpeed;
      utterance.onend = (event) => {
        setSpeaking(false);
        // console.log(
        //   `Utterance has finished being spoken after ${event.elapsedTime} seconds.`
        // );
      };
      utterance.onerror = (event) => {
        setSpeaking(false);
      };
      utterance.onstart = (event) => {
        setSpeaking(true);
        // console.log(`We have started uttering this speech: ${event.name}`);
      };
      window.speechSynthesis.speak(utterance);
    },
    [selectedVoice, settings.voiceSpeed],
  );

  const resetSetting = (setting: keyof typeof settings) => {
    setSettings({
      ...settings,
      [setting]: defaultSettingsRef.current[setting],
    });
  };

  const renderVoiceGroup = ([group, voicesInGroup]) => {
    const items = voicesInGroup.map((voice) => (
      <MenuItem
        key={voice.voiceURI}
        value={voice.voiceURI}
      >
        {voice.name}
      </MenuItem>
    ));
    return [<ListSubheader>{group}</ListSubheader>, items];
  };

  // if (!browserSupportsSpeechRecognition) {
  //   return (
  //     <div>
  //       This browser doesn't support speech recognition. Please use modern browsers with latest version, like Chrome.
  //     </div>
  //   );
  // }

  if (!isMicrophoneAvailable) {
    return (
      <div>
        Please allow Flastchat to access your microphone.
      </div>
    );
  }

  return (
    <Stack spacing={2}>
      <Stack>
        <InputLabel htmlFor="voice-name">Voice Name</InputLabel>
        <Stack direction="row" alignItems="center" spacing={2}>
          <FormControl fullWidth>
            <Select
              id="voice-name"
              sx={{ m: 1, minWidth: 120 }}
              value={settings.voiceURI}
              onChange={ event => {
                setSettings({
                  ...settings,
                  voiceURI: event.target.value,
                });
              }}
            >
              {Object.entries(availableVoices).map(
                (voiceGroup, index) => (
                  renderVoiceGroup(voiceGroup)
                )
              )}
            </Select>
          </FormControl>
          <Button
            size="large"
            variant="outlined"
            onClick={() => resetSetting('voiceURI')}
          >
            Reset
          </Button>
        </Stack>
      </Stack>

      <Stack>
        <InputLabel htmlFor="voice-speed">Speed</InputLabel>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Slider
            value={settings.voiceSpeed}
            min={0.5}
            step={0.1}
            max={2}
            onChange={(event: Event, newValue: number | number[]) => {
              if (typeof newValue === 'number') {
                setSettings({ ...settings, voiceSpeed: newValue });
              }
            }}
            aria-label="Voice speed"
          />
          <div>
            {`${settings.voiceSpeed.toFixed(2)}x`}
          </div>
          <Button
            size="large"
            variant="outlined"
            onClick={() => resetSetting('voiceSpeed')}
          >
            Reset
          </Button>
        </Stack>
      </Stack>
      <Stack alignSelf="flex-start">
      <Button
        size="large"
        variant="outlined"
        onClick={() => getSpeechFromAzure('It was a great chat', settings.voiceSpeed, settings.voiceURI)}
      >
        <Headphones strokeWidth={1} />
        <span style={{marginLeft: 4}}>Try Listening</span>
      </Button>
      </Stack>
    </Stack>
  );
}
