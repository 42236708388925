import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Footer from '../../components/footer/Footer';

import { useAuth } from "../../contexts/auth"

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  // overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const Main = styled('div')({
  flexGrow: 1,
  // overflow: 'auto',
  minHeight: '100%',
});

// ----------------------------------------------------------------------

export default function DashboardLayout({noRedirect}) {
  const [open, setOpen] = useState(false);
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => { // this is new!
    if (!user && !noRedirect) {
      navigate("/")
    }
  }, [navigate, noRedirect, user])

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} isLoggedIn={!!user} />

      {/* <Nav openNav={open} onCloseNav={() => setOpen(false)} /> */}
      { (!!user || noRedirect) &&
      <>
        <MainRoot>
          <Main>
            <Outlet />
          </Main>
        </MainRoot>
      <Footer />
      </>
      }
    </StyledRoot>
  );
}
