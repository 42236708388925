import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

export default function SupportPage() {
  return (
    <>
      <Helmet>
        <title> Support | Flastchat </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <ContactSupportIcon sx={{ fontSize: 48,  marginBottom: "16px" }} />
          <Typography variant="h3" paragraph>
            Support
          </Typography>

          <Typography sx={{ color: 'text.secondary', mt: 2, mb: 5 }}>
            Require assistance? Our committed support team is readily available to address any inquiries you might have. Feel free to reach out to us via email at
            help@flastchat.com .
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
