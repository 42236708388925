import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Stack, Box, Grid, Snackbar, Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    // paddingX: 1
  },
  [theme.breakpoints.up('lg')]: {
    // minHeight: '70vh',
  },
  // [theme.breakpoints.down('lg')]: {
  //   padding: theme.spacing(4, 2),
  //   maxWidth: 480,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   padding: theme.spacing(4, 0),
  //   maxWidth: 520,
  // },
  [theme.breakpoints.up('xl')]: {
    // maxWidth: 680,
    minHeight: '75vh',
  },
}));

const StyledImage = styled('div')(({ theme }) => ({
  // margin: 'auto',
  borderRadius: 10,
  display: 'flex',
  justifyContent: 'center',
  // flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    // paddingRight: '16px'
    paddingBottom: '16px'
  },
  [theme.breakpoints.up('md')]: {
    // minHeight: '70vh',
  },
  // [theme.breakpoints.down('lg')]: {
  //   padding: theme.spacing(4, 2),
  //   maxWidth: 480,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   padding: theme.spacing(4, 0),
  //   maxWidth: 520,
  // },
  // [theme.breakpoints.up('xl')]: {
  //   maxWidth: 680,
  // },
}));

const FeatureStyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 2),
  },
  [theme.breakpoints.up('sm')]: {
    // minHeight: '80vh',
    padding: theme.spacing(2, 0),
    maxWidth: '400px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '330px',
  },
  [theme.breakpoints.down('xl')]: {
    // padding: theme.spacing(2, 2),
    // maxWidth: '330px',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '350px',
  },

}));



// ----------------------------------------------------------------------

export default function LandingPage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xl'));

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // console.log(window.location.hash);
    // const queryParameters = new URLSearchParams('#error=unauthorized_client&type=401&error_description=Email+link+is+invalid+or+has+expired');
    const queryParameters = new URLSearchParams(window.location.hash);
    const callbackType = queryParameters.get("type");
    setHasError(!!queryParameters.get('error_description'));
    // http://localhost:3000/#access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjgyNjY4Nzg5LCJzdWIiOiJiY2Y3ODlkMy05NzdiLTQxOWUtOGY4OC03MTFjODQwMjgyMjkiLCJlbWFpbCI6IndheW5lLmNoYXRub3dAZ21haWwuY29tIiwicGhvbmUiOiIiLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJlbWFpbCIsInByb3ZpZGVycyI6WyJlbWFpbCJdfSwidXNlcl9tZXRhZGF0YSI6eyJ0YXJnZXRfaWVsdHNfc2NvcmUiOjd9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6Im90cCIsInRpbWVzdGFtcCI6MTY4MjY2NTE4OX1dLCJzZXNzaW9uX2lkIjoiMGFmMjY4NzctZjc4Ni00MmExLTk2NDItZjhmMWNmYjc4NTQ2In0.S0GeHyemgL9JApORXxQomzjQnC0KLUyfth3vw4q2LFk&expires_in=3600&refresh_token=6iucR2HF0zNgViL0JZeaNg&token_type=bearer&type=signup
    // 'http://localhost:3000/#access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjgyNjY4MjQ3LCJzdWIiOiIxMjQ2MGFkZC0yNzc2LTRjMzEtOTdkYi01OGQzNzU0OWJkZTIiLCJlbWFpbCI6IndheW5lLmNoYXRub3dAZ21haWwuY29tIiwicGhvbmUiOiIiLCJhcHBfbWV0YWRhdGEiOnsicHJvdmlkZXIiOiJlbWFpbCIsInByb3ZpZGVycyI6WyJlbWFpbCJdfSwidXNlcl9tZXRhZGF0YSI6eyJ0YXJnZXRfaWVsdHNfc2NvcmUiOjYuNX0sInJvbGUiOiJhdXRoZW50aWNhdGVkIiwiYWFsIjoiYWFsMSIsImFtciI6W3sibWV0aG9kIjoib3RwIiwidGltZXN0YW1wIjoxNjgyNjY0NjQ3fV0sInNlc3Npb25faWQiOiI1N2Q4Nzg0OC1iMmU4LTRlZWMtOTliZi05MTg5YTg5ODhkZTYifQ.ni61Zdnq4FrsGiyC9G6AcQ0G_GonzZILhcZ-Xi3oGgI&expires_in=3600&refresh_token=suXycAFw1nZ-TlaeST1Udg&token_type=bearer&type=email_change'
    const confirmMessage = (callbackType === 'signup' || callbackType === 'email_change') ? 'Your email address is confirmed. You can now log into Flastchat.' : '';
    setMessage(queryParameters.get('#message') || queryParameters.get('error_description') || confirmMessage);
    if(message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title> Home | Flastchat </title>
      </Helmet>

      <Box>
        <Grid container spacing={2} marginTop={0} sx={{ maxWidth: {lg: '1100px' , xl: '1540px'}, marginX: 'auto' }}>
          <Grid item xs={12} lg={7} marginBottom={10}>
            <StyledContent
              sx={{ alignItems: { xs: 'center', lg: 'flex-start' }, paddingRight: { xs: 4, lg: 0 }}}
            >
              <Typography sx={{ color: '#444943', fontSize: { xs: '2em', sm: '3em', lg: '3.3em' }, fontFamily: '"Work Sans", sans-serif', fontWeight: 400, marginBottom: 2}} paragraph>
                Talk the talk, { matches && <br/> } ace the test
              </Typography>

              <Typography sx={{ color: 'text.secondary', fontSize: { xs: '1em', sm: '1.3em' }, marginBottom: 6, maxWidth: {xs: '480px', sm:'480px', lg: '520px' , xl: '685px'} }} paragraph>
                {/* Flastchat uses GPT, an advanced language model, to provide personalized feedback and suggestions, helping to build your confidence
                and succeed in English speaking tests. */}
                {/* Flastchat uses ChatGPT, an advanced language model, to help you excel in your English speaking tests.
                Our app offers topic cards and a voice interaction feature to practice answering questions, receiving personalized feedback and examples.
                With this app, you can improve your English proficiency. */}
                {/* Flastchat is your ultimate tool for acing English speaking tests. Powered by ChatGPT, our app offers a range of features to help you master spoken English.
                Practice answering questions on various topics with our topic cards and voice interaction feature, and receive personalized feedback and example answers.
                With Flastchat, you can rapidly improve your English proficiency and feel confident in your speaking abilities. */}
                Flastchat leverages AI to help you excel in your IELTS speaking tests. Our app offers topic cards and voice interaction features
                for practicing speaking and receiving personalized feedback and examples.
                With Flastchat, you can quickly improve your English speaking skills.
              </Typography>
              <Stack direction="row">
                <Button
                  to="/signup"
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  sx={{
                    borderRadius: 35,
                    paddingX: 4,
                    fontSize: '1em !important',
                    background: "linear-gradient(217deg, rgba(101,181,85,1) 0%, rgba(188,199,39,1) 66%)",
                    "&:hover": {
                      background: "linear-gradient(135deg, #4cc2ff 0%, #4aa0fa 100%)"
                    }
                  }}
                >
                  Try For Free
                </Button>
              </Stack>
            </StyledContent>
          </Grid>
          {/* <Grid item xs={12} md={3} sx={{background: "linear-gradient(217deg, rgba(101,181,85,1) 0%, rgba(188,199,39,1) 66%)", display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Box
              component="img"
              src={`/assets/illustrations/${matches ? 'men' : 'mobile-men'}.png`}
              sx={{ height: { xs: 80, md: 180, xl: 260 }, paddingRight: { xs: 10, md: 0 }, mx: 'auto', my: { xs: 2, xl: 4 } }}
            />
            <Box
              component="img"
              src={`/assets/illustrations/${matches ? 'lady' : 'mobile-lady'}.png`}
              sx={{ height: { xs: 80, md: 180, xl: 260 }, paddingLeft: { xs: 10, md: 0 }, mx: 'auto', my: { xs: 2, xl: 4 } }}
            />
          </Grid> */}
          <Grid item xs={12} lg={5} marginBottom={10} sx={{ display: { xs: 'none', lg: 'flex' }, justifyContent: 'center', flexDirection: 'column' }} >
            <Box
              component="img"
              src={`/assets/illustrations/man-learning.png`}
              sx={{ height: { xs: 330, md: 330, lg: 400, xl: 500 }, mx: 'auto', my: { xs: 2, xl: 4 } }}
            />
          </Grid>
          <Grid item xs={12} lg={7} marginBottom={6} sx={{ backgroundColor: { xs: '#D8E168', lg: 'inherit' }}} >
            <StyledImage sx={{ backgroundColor: '#D8E168' }} >
              <Box
                component="img"
                src={`/assets/illustrations/flastchat-app.png`}
                sx={{ height: { xs: 230, sm: 400, lg: 400, xl: 500 }, width: { xs: 375, sm: 596, lg: 596, xl: 745 }, paddingRight: { xs: 4, lg: 0 }, my: { xs: 2, xl: 4 } }}
              />
            </StyledImage>
          </Grid>
          <Grid item xs={12} lg={5} marginBottom={6} sx={{ display: 'flex' }}  >
            <FeatureStyledContent sx={{ paddingRight: { xs: 4, lg: 0 }}}>
              <Stack direction="row" sx={{my: 0, justifyContent: { xs: 'center', lg: 'flex-start'}}}>
                <Typography sx={{ color: '#838483', mx: 0, my: 0, fontSize: { xs: '1.8em', sm: '2em', xl: '2.5em' }}} paragraph>
                  Our features
                </Typography>
              </Stack>
              <Stack direction="row" sx={{my: { xs: 2, sm: 4, lg: 4, xl: 6 }, paddingLeft: {xs: 1, sm: 2, lg: 0}, alignItems: 'center'}}>
                <Box
                  component="img"
                  src="/assets/illustrations/check-icon.png"
                  sx={{ height: 28 }}
                />
                <Typography sx={{ color: '#838483', mx: {xs: 2, lg: 2, xl: 3}, my: 0 }} paragraph>
                  Identify and correct grammatical, vocabulary, and coherence errors.
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ my: { xs: 2, sm: 4, lg: 4, xl: 6 }, paddingLeft: {xs: 1, sm: 2, lg: 0}, alignItems: 'center'}}>
                <Box
                  component="img"
                  src="/assets/illustrations/suggestion-icon.png"
                  sx={{ height: 28 }}
                />
                <Typography sx={{ color: '#838483', mx: {xs: 2, lg: 2, xl: 3}, my: 0 }} paragraph>
                  Provide suggestions for idiomatic words and phrases.
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ my: { xs: 2, sm: 4, lg: 4, xl: 6 }, paddingLeft: {xs: 1, sm: 2, lg: 0}, alignItems: 'center'}}>
                <Box
                  component="img"
                  src="/assets/illustrations/example-icon.png"
                  sx={{ height: 28 }}
                />
                <Typography sx={{ color: '#838483', mx: {xs: 2, lg: 2, xl: 3}, my: 0 }} paragraph>
                  Give personalized example answers tailored to your target score.
                </Typography>
              </Stack>
            </FeatureStyledContent>
          </Grid>
        </Grid>
      </Box>

      <Snackbar open={open} autoHideDuration={30000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose}>
        <Alert severity={hasError ? 'error' : 'info'} elevation={6} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
