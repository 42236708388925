import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { isEmail } from 'validator';
// components
import { useAuth } from "../../../contexts/auth";

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const { user, resetPasswordForEmail } = useAuth();
  const navigate = useNavigate();

  const [errorReset, setErrorReset] = useState('');
  const sendResetLinkHint = `If the email address exists in Flastchat, we'll send you an email with instructions on how to reset your password. Please check your email (including spam and junk folders) within the next few minutes. If you don't receive the email or need further assistance, please contact our support team.`;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/app');
    }
  }, [navigate, user]);

  const handleSendResetLink = async () => {

    if (!isEmail(email)) {
      setEmailError(true);
      return;
    }

    setLoading(true);
    try {
      const { error } = await resetPasswordForEmail(email);

      if (error) {
        console.log(error);
        setErrorReset(error?.message);
      } else {
        setErrorReset('');
        setEmail('');
      }
    } catch (error) {
      console.log(error);
      setErrorReset('Sorry, something went wrong. Please try again');
    } finally {
      setLoading(false);
      setOpen(true);
    }

  };

  const handleEmailChange = (event) => {
    if(emailError) {
      setEmailError(false);
    }
    setEmail(event.target.value);
  };

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }

    setOpen(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          type="email"
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? 'Please enter a valid email address' : ' '}
          required
          autoComplete="username"
          autoFocus
        />

      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 5 }} loading={loading}  onClick={handleSendResetLink}>
        Send Reset Link
      </LoadingButton>

      <Snackbar open={open} autoHideDuration={errorReset ? 10000 : 30000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleClose}>
        <Alert severity={errorReset ? 'error' : 'info'} elevation={6} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
          {errorReset || sendResetLinkHint}
        </Alert>
      </Snackbar>
    </>
  );
}
