import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Stack, TextField, Select, Snackbar, Alert, MenuItem, FormControl, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { isEmail } from 'validator';
// components
import { useAuth } from "../../../contexts/auth";

// ----------------------------------------------------------------------

export default function AccountForm() {
  const { user, updateUser } = useAuth();

  const [errorLogin, setErrorLogin] = useState('');
  const [updateHint, setUpdateHint] = useState('');
  const [email, setEmail] = useState(user.email);
  const [score, setScore] = useState(user.user_metadata.target_ielts_score);
  const [emailError, setEmailError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    // navigate('/dashboard', { replace: true });

    if (!isEmail(email)) {
      setEmailError(true);
      return;
    }

    setLoading(true);
    try {
      const { error } = await updateUser(
        (email !== user.email ? email : null),
        (score !== user.user_metadata.target_ielts_score ? score : null)
      );

      if (error) {
        console.log(error);
        setErrorLogin(error?.message);
      } else {
        setErrorLogin('');
        setUpdateHint( `Account updated! ${email !== user.email ? 'We will send confirmation emails shortly to both your current and new email addresses.' : ''}`);
      }
    } catch (error) {
      console.log(error);
      setErrorLogin('Sorry, there is an error. Please try again');
    } finally {
      setLoading(false);
      setOpen(true);
    }

  };

  const handleEmailChange = (event) => {
    if(emailError) {
      setEmailError(false);
    }
    setEmail(event.target.value);
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          type="email"
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? 'Please enter a valid email address' : ''}
          required
          autoComplete="username"
          autoFocus
        />
        <FormControl fullWidth>
          <InputLabel id="score-select-label">Target IELTS Score</InputLabel>
          <Select
            labelId="score-select-label"
            id="score-select"
            value={score}
            label="Target IELTS Score"
            onChange={handleScoreChange}
            required
          >
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={6.5}>6.5</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={7.5}>7.5</MenuItem>
            <MenuItem value={8}>8</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 5 }} loading={loading}  onClick={handleUpdate}>
        Save Changes
      </LoadingButton>

      <Snackbar open={open} autoHideDuration={errorLogin ? 10000 : 30000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleClose}>
        <Alert severity={errorLogin ? 'error' : 'info'} elevation={6} variant="filled" sx={{ width: '100%' }} onClose={handleClose}>
          {errorLogin || updateHint}
        </Alert>
      </Snackbar>
    </>
  );
}
