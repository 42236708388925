import PropTypes from 'prop-types';
// @mui
import { Box, Card, CardContent, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';

import { Link } from "react-router-dom"
// components
import Label from '../../../components/label';
import { useAuth } from "../../../contexts/auth";

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

TopicCard.propTypes = {
  topic: PropTypes.object,
};

export default function TopicCard({ topic }) {
  const { user, isLoadingUser } = useAuth();
  const { name, key, emoji, status } = topic;

  const cardStatus = user.is_subscribed ? '' : status;

  return (
    <Link to={cardStatus === 'disabled' ? '' : `../chat/${key}`} style={{textDecoration: 'none'}}>
      <Card sx={{ opacity: cardStatus === 'disabled' ? 0.7 : 1, backgroundColor: cardStatus === 'disabled' ? 'lightGrey' : 'inherit' }} className="topic-card">
        {cardStatus && (
          <LockIcon
            // variant="filled"
            // color={(cardStatus === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
            }}
          />
        )}
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Typography component="div" variant="h3">
              <span role="img" aria-label={name}>{emoji}</span>
            </Typography>
            <Typography component="div" variant="h5">
              {name}
            </Typography>
            {/* <Typography variant="subtitle1" color="text.secondary" component="div">
              Mac Miller
            </Typography> */}
          </Stack>
        </CardContent>

        {/* <Box sx={{ pt: '50%', position: 'relative' }}>
          {cardStatus && (
            <Label
              variant="filled"
              color={(cardStatus === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {cardStatus}
            </Label>
          )}
          <StyledProductImg alt={name} src={cover} />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>

            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>

        </Stack> */}
      </Card>
    </Link>
  );
}
