import { Helmet } from 'react-helmet-async';
import { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
// @mui
import { Box, Card, Container, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { useAuth } from '../contexts/auth';
import { useAppSettings } from '../contexts/appSettings';
import { ScoreSelect, VoiceForm } from '../sections/@dashboard/setup';

const steps = ['Select target score', 'Set voice preferences'];

// ----------------------------------------------------------------------

export default function SetupPage() {
  const { user, updateUser } = useAuth();
  const [score, setScore] = useState(6);

  const { appSettings, setAppSettings, saveAppSettings } = useAppSettings();

  const [activeStep, setActiveStep] = useState(0);

  const [loading, setLoading] = useState(false);
  const [hint, setHint] = useState('');

  const navigate = useNavigate();

  const totalSteps = () => steps.length;

  const isLastStep = () => activeStep === totalSteps() - 1;


  const handleNext = async () => {
    if (!isLastStep()) {
      await handleUpdateScore();
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    } else {
      handleComplete();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    saveAppSettings(appSettings);
    navigate("/app/topics");
  }

  const handleUpdateScore = async () => {
    if (score === user.user_metadata.target_ielts_score) {
      return;
    }
    setLoading(true);
    try {
      const { error } = await updateUser(null, score);

      if (error) {
        console.log(error);
        setHint(error?.message);
      }
    } catch (error) {
      console.log(error);
      setHint('Sorry, there is an error. Please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Setup | Flastchat </title>
      </Helmet>

      <Container maxWidth="sm">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Setup
        </Typography>

        <Card sx={{ width: '100%', p: { xs: 2, sm: 3 }, display: 'flex', flexDirection: 'column', minHeight: '520px' }}>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, mt: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignContent: 'center', p: {sm: 3} }}>
              {activeStep === 0 && <ScoreSelect score={score} setScore={setScore} />}
              {activeStep === 1 && <VoiceForm settings={appSettings} setSettings={setAppSettings} />}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                size="large"
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 3 }}
              >
                Back
              </Button>
              <LoadingButton
                size="large"
                variant="contained"
                loading={loading}
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </LoadingButton>
            </Box>
          </Box>
        </Card>
      </Container>
    </>
  );
}
