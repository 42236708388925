import { useState, useEffect } from 'react';
import { VolumeUp, VolumeDown } from '@mui/icons-material';

const SoundAnimation = () => {
  const [isVolumeUp, setIsVolumeUp] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVolumeUp((prevVolumeUp) => !prevVolumeUp);
    }, 800);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '24px' }}>
      {isVolumeUp ? <VolumeUp /> : <VolumeDown />}
    </div>
  );
};

export default SoundAnimation;
