import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// components
import { AccountForm, ChangePasswordForm } from '../sections/@dashboard/account';

// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      style={{display: 'flex', alignItems: 'center', flexGrow: (value === index ? 1 : 0) }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { sm: 3 }, flexGrow: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index, isMobile) {
  return {
    id: `account-tab-${index}`,
    'aria-controls': `account-tabpanel-${index}`,
    ... isMobile && {iconPosition: 'start'}
  };
}

export default function AccountPage() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Account | Flastchat </title>
      </Helmet>

      <Container maxWidth="sm">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Account
        </Typography>

        <Card sx={{ width: '100%', p: { xs: 1, sm: 3 }, minHeight: '520px', display: 'flex', flexDirection: 'column'}}>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="account tabs" variant="scrollable" scrollButtons>
              <Tab label="General" icon={<PersonIcon />} sx={{ marginRight: { sm: 5 }}} {...a11yProps(0, smUp)} />
              <Tab label="Change Password" icon={<VpnKeyIcon />} sx={{ marginRight: { sm: 5 }}} {...a11yProps(1, smUp)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AccountForm />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePasswordForm />
          </TabPanel>
        </Card>
      </Container>
    </>
  );
}
