import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import '@stripe/stripe-js';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';

import { AuthProvider } from "./contexts/auth";
import { AppSettingsProvider } from "./contexts/appSettings";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <AppSettingsProvider>
            <ThemeProvider>
              <ScrollToTop />
              <Router />
            </ThemeProvider>
          </AppSettingsProvider>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
