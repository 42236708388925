import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';

import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import createSpeechServicesPonyfill from 'web-speech-cognitive-services';

import {
  GitHub,
  Settings,
  FilePlus,
  Mic,
  Activity,
  Loader,
  AlertTriangle,
  X,
  ChevronDown,
  ChevronUp,
  Check,
  Headphones,
  Info,
} from 'react-feather';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import CloseIcon from '@mui/icons-material/Close';

import { isDesktop, isMobile, isSafari } from 'react-device-detect';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  TextField,
  Fab,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Select,
  Tooltip,
  ListSubheader,
  Slider,
  FormControl
} from '@mui/material';
import { keyframes } from '@mui/system';
import MicIcon from '@mui/icons-material/Mic';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import MicNoneIcon from '@mui/icons-material/MicNone';

import { styled } from "@mui/material/styles";

import { useNavigate, useParams } from 'react-router-dom';

import SendIcon from '@mui/icons-material/Send';
// components
import Iconify from '../components/iconify';
import { ChatMessage, TopicSelect } from '../sections/@dashboard/chat';
import * as Storage from '../utils/storage';
import usePrevious from '../hooks/usePrevious';
import { supabase } from "../services/supabase";
import { useAuth } from "../contexts/auth";
import { useAppSettings } from '../contexts/appSettings';
import { getSpeechToken, getSpeechFromAzure, availableVoices } from '../services/azure';
// mock
import TOPICS from '../_data/topics';
// ----------------------------------------------------------------------


interface CreateChatGPTMessageResponse {
  answer: string;
  messageId: string;
}

interface Message {
  type: 'prompt' | 'response';
  text: string;
  partIndex?: number;
}

interface TopicQuestions {
  part1: string[],
  part2: string[],
  part3: string[]
}

interface TopicMessageResponse {
  questions: TopicQuestions;
  messageId: string;
}


interface VoiceMappings {
  [group: string]: SpeechSynthesisVoice[];
}

const blink = keyframes`
  from { opacity: 0.2; }
  to { opacity: 1; }
`;

const BlinkedBox = styled('div')({
  // backgroundColor: 'red',
  // width: 30,
  // height: 30,
  display: 'flex',
  alignItems: 'center',
  animation: `${blink} 1.3s linear infinite`,
});

const savedData = Storage.load();

const ChatContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // height: "calc(100% - 64px)",
  height: "100%",
  padding: "8px",
  [theme.breakpoints.up("sm")]: {
    padding: "16px",
  }
}));

export default function ChatPage() {
  const { topic } = useParams();
  const chitChat = false;

  const {
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    transcript,
    listening: isListening,
    // finalTranscript,
    resetTranscript,
  } = useSpeechRecognition();

  // const prevFinalTranscript = usePrevious(finalTranscript);

  const initialMessages: Message[] = [
    { type: 'response', text: 'Try speaking to the microphone.' },
  ];

  const { appSettings: settings, defaultSettingsRef, setAppSettings: setSettings, saveAppSettings } = useAppSettings();

  // const defaultSettingsRef = useRef({
  //   host: process.env.REACT_APP_URL_SUPABASE,
  //   voiceURI: '',
  //   voiceSpeed: 1,
  // });
  const [isProcessing, setIsProcessing] = useState(false);
  const [messages, setMessages] = useState<Message[]>(chitChat ? initialMessages : []);
  const [isInitializing, setIsInitializing] = useState(true);
  const [questions, setQuestions] = useState<TopicQuestions>();
  // const [settings, setSettings] = useState({
  //   host: (savedData?.host as string) ?? defaultSettingsRef.current.host,
  //   voiceURI:
  //     (savedData?.voiceURI as string) ?? defaultSettingsRef.current.voiceURI,
  //   voiceSpeed:
  //     (savedData?.voiceSpeed as number) ??
  //     defaultSettingsRef.current.voiceSpeed,
  // });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [azureToken, setAzureToken] = useState(null);

  const [isStarting, setIsStarting] = useState(false);

  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
  const abortRef = useRef<AbortController | null>(null);
  const conversationRef = useRef({ currentMessageId: '' });

  const { user } = useAuth();

  const TOPIC_OPTIONS = TOPICS.filter((topic) => topic.status !== 'disabled' || user.is_subscribed).map((topic) => ({value: topic.key, label: topic.name}));


  const selectedVoice = useMemo(() => {
    return availableVoices.values?.find((voice) => voice.voiceURI === settings.voiceURI);
  }, [settings.voiceURI]);

  const recognizeSpeech = () => {
    if (isListening) {
      const finalTranscript = transcript;
      SpeechRecognition.abortListening();
      stopListening(finalTranscript);
    } else {
      window.speechSynthesis.cancel();
      resetTranscript();

      getSpeechToken()
      .then(({ token, region }) => {
        if(azureToken !== token) {
          const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
            credentials: {
              region,
              authorizationToken: token,
            }
          });
          SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
        } else {
          setAzureToken(token);
        }

        SpeechRecognition.startListening({
          continuous: true,
          language: 'en-US'
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  const undoLastVoiceInput = () => {
    setMessages((oldMessages) => (oldMessages.slice(0, -2)));
    // recognizeSpeech();
  }

  const resetConversation = () => {
    setIsProcessing(false);
    setMessages(chitChat ? initialMessages : []);
    setQuestions({ part1: [], part2: [], part3: []});
    conversationRef.current = { currentMessageId: '' };

    window.speechSynthesis.cancel();
    SpeechRecognition.abortListening();
    abortRef.current?.abort();

    if (!chitChat) {
      setIsInitializing(true);
      abortRef.current = new AbortController();

      const host = settings.host;

      supabase.functions.invoke(`topics/${topic}/questions`, { method: 'GET'})
        .then(({data, error}) => {
          if(error) {
            throw error;
          }
          const questions = data[0].questions;
          questions.part2 = [questions.part2.join('\n')];
          setQuestions(questions);
          setMessages([{ type: 'response', text: questions.part1[0], partIndex: 1 }]);
        })
        .catch((err: unknown) => {
          console.warn(err);
          // Connection refused
          const response = 'Failed to get the response, please reset the conversation and try again.';

          setMessages([{ type: 'response', text: response }]);
        })
        .finally(() => {
          setIsInitializing(false);
        });
    }
  };

  const handleModalOpenChange = (isOpen: boolean) => {
    setIsModalVisible(isOpen);
    // Storage.save(settings);
    saveAppSettings(settings);
  };

  const resetSetting = (setting: keyof typeof settings) => {
    setSettings({
      ...settings,
      [setting]: defaultSettingsRef.current[setting],
    });
  };

  const getResponseFromChatGPT = (requestText: string, path='chatgpt/messages', hint='') => {
    setIsProcessing(true);

    abortRef.current = new AbortController();

    supabase.functions.invoke(`instructor/${path}`, {
      body: {
        text: requestText,
        targetScore: user.user_metadata.target_ielts_score,
        parentMessageId: conversationRef.current.currentMessageId || undefined,
      },
    })
      .then(({data, error}) => {
        if(error) {
          throw error;
        }
        conversationRef.current.currentMessageId = data.messageId;
        setMessages((oldMessages) => [
          ...oldMessages,
          { type: 'response', text: hint + data.answer },
        ]);
      })
      .catch((err: unknown) => {
        console.warn(err);
        // Connection refused
        const response = 'Failed to get the response, please try again.';

        setMessages((oldMessages) => [
          ...oldMessages,
          { type: 'response', text: response },
        ]);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }

  useEffect(() => {
    resetConversation();

    return () => {
      SpeechRecognition.abortListening();
    }
  }, [topic]);

  const stopListening = useCallback((finalTranscript: string) => {
    // Only run effect if finalTranscript change from undefined or ''
    // to a non-empty string.
    // if (prevFinalTranscript || !finalTranscript) {
    //   return;
    // }

    setMessages((oldMessages) => [
      ...oldMessages,
      { type: 'prompt', text: finalTranscript, partIndex: oldMessages[oldMessages.length - 1].partIndex },
    ]);

    if(chitChat) {
      getResponseFromChatGPT(finalTranscript);
    } else {
      let partIndex = messages[messages.length - 1].partIndex;
      const partQuestionCount = messages.filter(message => message.type === 'response' && message.partIndex === partIndex).length;
      let partName = `part${partIndex}`;
      let questionIndex =  partQuestionCount;
      let hint = '';
      if(partIndex <= 3 && partQuestionCount >= questions[partName].length) {
        partIndex += 1;
        partName = `part${partIndex}`;
        questionIndex =  0;
        if(partIndex === 2) {
          hint = `Great. Let's move on to part two of the speaking practice.\n`;
        } else if(partIndex === 3) {
          hint = `Alright. Let's move on to part three of the speaking practice.\n`;
        } else if(partIndex === 4) {
          hint = 'Thank you for your response. It was a pleasure practicing with you.\n';
        }
      }

      if(partIndex <= 3) {
        setMessages((oldMessages) => {
          return [
            ...oldMessages,
            { type: 'response', text: hint + questions[partName][questionIndex], partIndex },
          ];
        });
      } else if(partIndex === 4) {
        let fullText = messages.reduce(
          (acc, message) => acc + (message.type === 'response' ? `Question: ${message.text}\n` : `My answer: ${message.text}\n`),  //
          ''
        );
        fullText += `My answer: ${finalTranscript}\n`;
        getResponseFromChatGPT(fullText, 'assess', hint);  // 'chatgpt/assess'
      } else if(partIndex === undefined) {
        setMessages((oldMessages) => {
          return [
            ...oldMessages,
            { type: 'response', text: 'Sorry, we have already finished the practice. Please reset the conversation or select other topics.' },
          ];
        });
      }
    }

  }, [messages]);

  const handleClose = () => {
    setIsModalVisible(false);
    // Storage.save(settings);
    saveAppSettings(settings);
  };

  const navigate = useNavigate();

  const renderVoiceGroup = ([group, voicesInGroup]) => {
    const items = voicesInGroup.map((voice) => (
      <MenuItem
        key={voice.voiceURI}
        value={voice.voiceURI}
      >
        {voice.name}
      </MenuItem>
    ));
    return [<ListSubheader>{group}</ListSubheader>, items];
  };

  // if (!browserSupportsSpeechRecognition) {
  //   return (
  //     <div>
  //       This browser doesn't support speech recognition. Please use modern browsers with latest version, like Chrome.
  //     </div>
  //   );
  // }

  if (!isMicrophoneAvailable) {
    return (
      <div>
        Please allow Flastchat to access your microphone.
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title> Chat | Flastchat </title>
      </Helmet>

      <Container style={{height: '100%'}}>
        <Grid container mb={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TopicSelect
              topic={topic}
              options={TOPIC_OPTIONS}
              onSelect={(topicObj) => topicObj && navigate(`/app/chat/${topicObj.value}`)}
            />
          </Grid>
        </Grid>
        <Card style={{height: '100%'}}>
          <ChatContainer>
            <ChatMessage
              topic={topic}
              messages={messages}
              transcript={transcript}
              isListening={isListening}
              isInitializing={isInitializing}
              isProcessing={isProcessing}
              settings={settings}
              currentMessageId={conversationRef.current.currentMessageId}
              undoLastVoiceInput={undoLastVoiceInput}
              isStarting={isStarting}
              setIsStarting={setIsStarting}
            />
            <Stack direction="row" spacing={2} style={{ justifyContent: "center", marginTop: "8px" }}>
              <Tooltip title="Voice Settings">
                <IconButton
                  color="primary"
                  aria-label="Settings"
                  disabled={isListening}
                  onClick={() => setIsModalVisible(true)}
                >
                  <GraphicEqIcon/>
                </IconButton>
              </Tooltip>
              <Dialog fullWidth maxWidth={'xs'} open={isModalVisible} onClose={handleClose}>
                <DialogTitle>
                  Voice
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                     <CloseIcon />
                   </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Stack spacing={2}>
                  <Stack>
                    <InputLabel htmlFor="voice-name">Name</InputLabel>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <FormControl fullWidth>
                        <Select
                          id="voice-name"
                          sx={{ m: 1, minWidth: 120 }}
                          value={settings.voiceURI}
                          onChange={ event => {
                            setSettings({
                              ...settings,
                              voiceURI: event.target.value,
                            });
                          }}
                        >
                          {Object.entries(availableVoices).map(
                            (voiceGroup, index) => (
                              renderVoiceGroup(voiceGroup)
                            )
                          )}
                        </Select>
                      </FormControl>
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={() => resetSetting('voiceURI')}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </Stack>

                  <Stack>
                    <InputLabel htmlFor="voice-speed">Speed</InputLabel>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Slider
                        value={settings.voiceSpeed}
                        min={0.5}
                        step={0.1}
                        max={2}
                        onChange={(event: Event, newValue: number | number[]) => {
                          if (typeof newValue === 'number') {
                            setSettings({ ...settings, voiceSpeed: newValue });
                          }
                        }}
                        aria-label="Voice speed"
                      />
                      <div>
                        {`${settings.voiceSpeed.toFixed(2)}x`}
                      </div>
                      <Button
                        size="large"
                        variant="outlined"
                        onClick={() => resetSetting('voiceSpeed')}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack alignSelf="flex-start">
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => getSpeechFromAzure('It was a great chat', settings.voiceSpeed, settings.voiceURI)}
                  >
                    <Headphones strokeWidth={1} />
                    <span style={{marginLeft: 4}}>Try Listening</span>
                  </Button>
                  </Stack>
                  </Stack>
                </DialogContent>
              </Dialog>

              <Tooltip title="Speak">
                <span>
                  <IconButton
                    color="primary"
                    aria-label={
                      isListening
                        ? 'Listening'
                        : 'Start speaking'
                      }
                    onClick={recognizeSpeech}
                    disabled={isProcessing || !isStarting}
                  >
                    {isListening? (
                      <BlinkedBox>
                        <MicIcon fontSize="large" />
                      </BlinkedBox>
                      ) : (
                      <MicNoneIcon fontSize="large" />
                    )}
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Reset Conversation">
                <IconButton color="primary" aria-label="New conversation" onClick={resetConversation}>
                  <RestartAltIcon/>
                </IconButton>
              </Tooltip>
            </Stack>
          </ChatContainer>
        </Card>
      </Container>
    </>
  );
}
